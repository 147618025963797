<template>
  <div class="comments">
    <div class="title">
      <strong>商品评价</strong> <span class="title-more">共{{ count }}条评价</span>
    </div>
    <div class="chat">
      <div class="item">
        <div class="avatar-area">
          <img src="../../static/doctor/avatar.png" class="avatar"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      page: 1,
      limit: 5,
      count: 0,
      list: [],
    };
  },
  mounted() {
    this.getChats();
  },
  methods: {
    getChats() {
      let data = {
        page: this.page,
        limit: this.limit,
        goods_id: this.$route.query.id,
      };
      this.$http.goods_comments(data).then((res) => {
        let { data, code, msg } = res;
        if (code == 1) {
          this.list = data.items;
          this.count = data.total;
        } else {
          this.$store.commit("showToast", msg);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./goods.scss";
.comments {
  width: 840px;
  padding: 20px 0;
  .title {
    font-size: 18px;
    color: #333;
    height: 36px;
    line-height: 36px;
  }
  .title-more {
    margin-left: 30px;
    font-size: 16px;
    color: #999;
  }
  .chat {
    width: 800px;
    padding: 10px;
    border: 1px solid #e6e7e9;
    border-radius: 4px;
    // display: flex;
    .item {
      display: flex;
      padding: 10px 15px 20px 15px;
      width: 750px;
      border-bottom: 1px solid #e6e7e9;
    }
    .info-area {
      width: 120px;
      text-align: center;
      .avatar {
        width: 100px;
        height: 100px;
        border-radius: 50%;
      }
    }
  }
}
</style>
