<template>
  <div class="page">
    <div class="banner">
      <img class="goods-img" :src="$host + info.image_default_id" />
      <div class="goods-info">
        <div class="title">
          {{ info.name }}
        </div>
        <div class="desc">
          无需任何额外消费
          <span style="margin-left: 50px">已售 {{ info.virtual_sales }}</span>
          <b-link style="margin-left: 50px; text-decoration: none; color: #333"
            ><b-icon icon="star" style="margin-right: 5px"></b-icon>收藏</b-link
          >
        </div>
        <div class="desc">
          安心购
          <span style="margin-left: 50px; color: #999">
            <img src="../../static/shop/money.png" class="goods-icon" />随时退
          </span>
          <span style="margin-left: 50px; color: #999">
            <img src="../../static/shop/clock.png" class="goods-icon" />极速退
          </span>
          <span style="margin-left: 50px; color: #999">
            <img src="../../static/shop/back.png" class="goods-icon" />日记返现
          </span>
        </div>
        <div class="desc">
          价格:
          <span style="margin-left: 50px; font-size: 18px; color: #fb776a"
            >￥{{ info.product ? info.product.price : 0 }}</span
          >
        </div>
        <div class="nums">
          <div>预定数量:</div>
          <b-form-spinbutton
            style="width: 120px; margin-left: 30px"
            v-model="value"
            min="1"
            max="100"
          ></b-form-spinbutton>
        </div>
        <div class="nums">
          使用时间: <span style="margin-left: 30px; color: #999">支付后30天内到院</span>
        </div>
        <div class="nums">
          <div>
            支付方式:
            <span style="margin-left: 30px; color: #999">预约金 </span>
            <span style="font-size: 18px; color: #fb776a">
              ￥{{ info.product ? info.product.earnest_money : 0 }}</span
            >
            <span style="margin-left: 30px; color: #999"
              >到院再付 ￥{{
                info.product
                  ? Number(info.product.price - info.product.earnest_money)
                  : ""
              }}
            </span>
          </div>
          <div
            class="order-btn"
            style="background: linear-gradient(90deg, #fda487 0%, #ff7758 100%)"
            @click="toBuy"
          >
            立即预定
          </div>
          <div
            class="order-btn"
            style="background: linear-gradient(90deg, #f9b359 0%, #f89659 100%)"
          >
            <b-icon icon="chat-dots" style="margin-right: 5px"></b-icon>咨询
          </div>
        </div>
      </div>
    </div>

    <div class="content">
      <div style="width: 840px">
        <div class="tabs">
          <div class="tab" @click="tab = 0" :class="tab == 0 ? 'active' : ''">
            项目详情
          </div>
          <div class="tab" @click="tab = 1" :class="tab == 1 ? 'active' : ''">评价</div>
        </div>
        <div v-show="tab == 0">
          <div class="table">
            <table
              style="background: #faf9f9; text-align: center; border: 1px solid #e6e7e9"
            >
              <tr>
                <th width="150">项目名称</th>
                <th width="150">项目组合</th>
                <th width="150">医生/级别</th>
                <th width="150">价格</th>
              </tr>
              <tr>
                <td>{{ info.product ? info.product.name : "" }}</td>
                <td></td>
                <td></td>
                <td>{{ info.product ? info.product.price : "" }}</td>
              </tr>
            </table>
          </div>
          <ul class="flow">
            <div class="title">服务流程</div>
            <li>
              <div class="li-left">面诊</div>
              <div class="li-center"></div>
              <div class="li-right">30分钟</div>
            </li>
            <li>
              <div class="li-left">术前准备</div>
              <div class="li-center"></div>
              <div class="li-right">30分钟</div>
            </li>
            <li>
              <div class="li-left">手术</div>
              <div class="li-center"></div>
              <div class="li-right">90分钟</div>
            </li>
            <li>
              <div class="li-left">术后处理</div>
              <div class="li-center"></div>
              <div class="li-right">60分钟</div>
            </li>
          </ul>
          <div class="flow">
            <div class="title">购买流程</div>
            <div class="item" v-for="(item, index) in flows" :key="index">
              <div class="order-icon">{{index+1}}</div>
              <div class="item-center">
                {{item.title}}
                <span class="item-desc">{{item.desc}}</span>
              </div>
            </div>
          </div>

          <ul class="ol">
            <div class="title">购买须知</div>
            <li>有效期: 购买之日起30天内有效</li>
            <li>预约信息: 该项目需避开浦乳期、孕期、经期</li>
            <li>可用时间: 全年法定工作日内</li>
            <li>消费流程: 支付预约金进行预约，到院验证预约码支付尾款</li>
            <li>手术须知: 素颜，避开哺乳期、经期、孕期</li>
          </ul>
          <div style="margin: 10px 0">
            <div class="ol-title">价格说明</div>
            <p class="ol-desc">
              划线价格:
              商品展示的划线价格为全国医美机构统一门市价，即为美蜂平台展示的销售价，但由于时间、地区的差异性以及
              市场行情的波动，该价格可能与您购买时展示的价格有波动，仅供参考！<br />
              非划线价格:
              商品的实时标价，并非原价，不因表述的差异改变性质，是您决定是否购买商品的凭据。
            </p>
          </div>
        </div>
        <div v-show="tab == 1">
          <Chats></Chats>
        </div>
      </div>
      <div class="right-area">
        <div class="border-area">
          <div class="title">推荐医生</div>

          <div
            class="item"
            v-for="(item, index) in doctors"
            :key="index"
            @click="toInfo(item.id)"
          >
            <img class="avatar" :src="item.touxiang" @error="defaultAvatar(index)" />
            <div class="doctor">
              <div class="doctor-name">
                <div style="width: 178px">{{ item.nickname }}</div>
                <div class="zixun">
                  <b-icon icon="chat-dots-fill"></b-icon>
                  咨询
                </div>
              </div>
              <div class="doctor-dept">
                {{ item.position ? item.position.name : "未认证医生" }}
              </div>
              <div class="doctor-rate" style="font-size: 12px">
                <span>好评率：{{ item.favorable_rate }}%</span>
                <!-- <span style="margin-left: 7.5px">服务人次1807</span> -->
                <span style="margin-left: 15px">案例 {{ item.user_order }}</span>
              </div>
              <div class="doctor-like">
                擅长：
                <b-badge
                  style="background: #e6e7e9; margin-left: 10px; color: #666"
                  v-for="(selt, i) in item.collection_list.slice(0, 2)"
                  :key="i"
                  >{{ selt.collection_name }}</b-badge
                >
              </div>
            </div>
          </div>
        </div>

        <div class="border-card">
          <div class="border-title">美蜂整形咨询师</div>
          <div class="card-content">
            <img
              src="../../static/community/img_girl.png"
              style="width: 110px; margin-left: 20px; height: auto"
            />
            <div style="margin-left: 10px">
              <div class="border-card-title">立即了解</div>
              <div class="border-card-desc">适合自身的变美方案</div>
              <div class="border-card-consult">我要咨询</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Chats from "./goodsChat.vue"
export default {
  data() {
    return {
      value: 1,
      info: {},
      doctors: [],
      tab: 0,
      flows: [
        { title: "选择服务项目", desc: "选择您需要的服务项目"},
        { title: "确认订单", desc: "选择医生 > 选择时间 > 选择医院"},
        { title: "支付预约金", desc: "支付完成，到所预约医院进行面诊"},
        { title: "支付尾款", desc: "面诊结束后，支付尾款"},
      ]
    };
  },
  components: {
    Chats
  },
  mounted() {
    this.getInfo();
    this.getDoctors();
  },
  methods: {
    getInfo() {
      let data = {
        id: this.$route.query.id,
      };
      this.$http.shop_goodsdetail(data).then((res) => {
        let { data, code, msg } = res;
        if (code == 1) {
          this.info = data;
        } else {
          this.$store.commit("showToast", msg);
        }
      });
    },
    getDoctors() {
      let data = {
        limit: 5,
        page: 1,
        is_recomment: true,
      };
      this.$http.doctors(data).then((res) => {
        console.log(res);
        let { data, code, msg } = res;
        if (code == 1) {
          data.items.forEach((item) => {
            item.touxiang = this.$host + item.avatar;
          });
          this.doctors = data.items;
        } else {
          this.$store.commit("showToast", msg);
        }
      });
    },
    toInfo(id) {
      this.$router.push({
        path: "/doctor_info?id=" + id,
      });
    },
    toBuy() {
      if (this.value == 0) return this.$store.commit("showToast", "请选择商品数量");
      let data = {
        product_id: this.$route.query.id,
        num: this.value,
      };
      this.$http.fastbuy(data).then((res) => {
        let { code, msg } = res;
        if (code == 1) {
          let url =
            "/setorder?id=" +
            this.$route.query.id +
            "&num=" +
            this.value +
            "&goods_id=" +
            this.info.id;
          this.$router.push({
            path: url,
          });
        } else {
          this.$store.commit("showToast", msg);
        }
      });
    },
    defaultAvatar(i) {
      this.doctors[i].touxiang = require("../../static/doctor/avatar.png");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./goods.scss";
</style>
